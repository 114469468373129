/*!

=========================================================
* Vue Argon Dashboard - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import ArgonDashboard from './plugins/argon-dashboard'
import VueSweetalert2 from 'vue-sweetalert2';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import VCalendar from 'v-calendar';
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCryptojs from 'vue-cryptojs'
import firebase from 'firebase'
import vuetify from '@/plugins/vuetify' // path to vuetify export

import VueDatePicker from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';

Vue.use(VueDatePicker);

// Options: You can set lang by default
Vue.use(VueDatePicker, {
    lang: 'fr'
});

Vue.use(VueCryptojs)

Vue.component('v-select', vSelect)

// If you don't need the styles, do not connect

Vue.use(VueSweetalert2);

Vue.prototype.$http = axios
Vue.prototype.$http.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("token")
Vue.prototype.$http.defaults.headers.common['X-Requested-With'] = "XMLHttpRequest"

Vue.config.productionTip = false

var config = {
    apiKey: "AIzaSyB8Cqmn3R_Ll86VDf3oVdQ6Fi6JbHQakVA",
    authDomain: "sirulin-86a98.firebaseapp.com",
    projectId: "sirulin-86a98",
    storageBucket: "sirulin-86a98.appspot.com",
    messagingSenderId: "948121220309",
    appId: "1:948121220309:web:50b49069655536cdb85e5c",
    measurementId: "G-1PZZVYEXJX"
};
firebase.initializeApp(config);

Vue.prototype.$messaging = firebase.messaging()

Vue.use(VCalendar)
Vue.use(ArgonDashboard)
new Vue({
    vuetify,
    router,
    axios,
    VueAxios,
    render: h => h(App)
}).$mount('#app')